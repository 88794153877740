import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

import useDebounce from 'v2/ui/utils/useDebounce'

const PERSIST_DEBOUNCE_RATE = 500 // ms

export function useTableViewGridState() {
    const { columns, isRecordActionsColumnPinned, view, isEmbedded } = useTableViewContext()
    const viewRef = useRef(view)
    viewRef.current = view
    const columnsRef = useRef(columns)
    columnsRef.current = columns
    const isRecordActionsColumnPinnedRef = useRef(isRecordActionsColumnPinned)
    isRecordActionsColumnPinnedRef.current = isRecordActionsColumnPinned

    const debouncedPersistFixedColumnWidths = useDebounce(
        useCallback(
            (widths: Record<string, number>) => persistFixedColumnWidths(viewRef.current, widths),
            []
        ),
        PERSIST_DEBOUNCE_RATE
    )

    const fixedWidthsRef = useRef<Record<string, number>>(
        isEmbedded ? {} : restoreFixedColumnWidths(view)
    )

    const gridRef = useRef<HTMLDivElement>(null)

    const onColumnResize = useCallback(
        (columnId: string, width: number) => {
            const grid = gridRef.current
            if (!grid) return

            const columns = columnsRef.current
            const column = columns.find((column) => column.id === columnId)
            if (!column) return

            fixedWidthsRef.current[columnId] = width
            if (!isEmbedded) {
                debouncedPersistFixedColumnWidths(fixedWidthsRef.current)
            }

            requestAnimationFrame(() => {
                const gridTemplateColumns = makeGridTemplateColumns(
                    columnsRef.current,
                    isRecordActionsColumnPinnedRef.current,
                    fixedWidthsRef.current
                )
                grid.style.gridTemplateColumns = gridTemplateColumns
            })
        },
        [debouncedPersistFixedColumnWidths, isEmbedded]
    )

    // Update grid template columns when columns change.
    useLayoutEffect(() => {
        const grid = gridRef.current
        if (!grid) return

        requestAnimationFrame(() => {
            const gridTemplateColumns = makeGridTemplateColumns(
                columns,
                isRecordActionsColumnPinned,
                fixedWidthsRef.current
            )

            grid.style.gridTemplateColumns = gridTemplateColumns
        })
    }, [columns, isRecordActionsColumnPinned])

    return useMemo(() => ({ gridRef, onColumnResize }), [gridRef, onColumnResize])
}

export function makeGridTemplateColumns(
    columns: TableViewColumn[],
    isRecordActionsColumnPinned: boolean,
    fixedWidths: Record<string, number>
) {
    const spanWidth = 'minmax(max-content, auto)'

    const configParts = columns.reduce((acc, column) => {
        if (fixedWidths.hasOwnProperty(column.id)) {
            acc.push(`${fixedWidths[column.id]}px`)
        } else if (column.maxWidth) {
            acc.push(`fit-content(${column.maxWidth}px)`)
        } else {
            acc.push(spanWidth)
        }

        return acc
    }, [] as string[])

    // If the record action menu is not pinned, add a column for it.
    if (!isRecordActionsColumnPinned) {
        configParts.push(spanWidth)
    }

    // Make sure at least one of the columns can span the full width.
    if (configParts.length > 1 && !configParts.some((part) => part === spanWidth)) {
        configParts[configParts.length - 1] = spanWidth
    }

    return configParts.join(' ')
}

function getFixedColumnWidthsStorageKey(view: ViewDto) {
    return `TableViewFixedColumnWidths_${view.stack_id}_${view._sid}`
}

function persistFixedColumnWidths(view: ViewDto, widths: Record<string, number>) {
    const key = getFixedColumnWidthsStorageKey(view)
    localStorage.setItem(key, JSON.stringify(widths))
}

function restoreFixedColumnWidths(view: ViewDto) {
    const key = getFixedColumnWidthsStorageKey(view)
    const value = localStorage.getItem(key)

    return value ? JSON.parse(value) : {}
}
